import React, { Component } from 'react';

class AboutV1 extends Component {

    render() {

		let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pt-120 pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-left">
			          <img src={publicUrl+"assets/img/premios/premios.jpg"} alt="About Us" />
			          <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-20">
			            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Sobre Nosotros</h6>
			            <h1 className="section-title">La agencia inmobiliaria
			              lider en Terrassa<span>.</span></h1>
			            <p>Nuestros agentes tienen muchos años de experiencia en el mercado de la vivienda de esta ciudad.</p>
			          </div>
			          <ul className="ltn__list-item-half clearfix">
			            <li>
			              <i className="flaticon-home-2" />
			              Venta a medida
			            </li>
			            <li>
			              <i className="flaticon-mountain" />
			             El mejor servicio y atención
			            </li>
			            <li>
			              <i className="flaticon-heart" />
			              Podemos hablar sin compromiso ni coste
			            </li>
			            <li>
			              <i className="flaticon-secure" />
			              ¡Mis resultados me avalan!
			            </li>
			          </ul>
			          <div className="ltn__callout bg-overlay-theme-05  mt-30">
			            <p>"Le ayudamos a vender y comprar su vivienda al mejor precio" </p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV1