import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV5 from './components/home-v5';
import Shop from './components/shop';
import ProdductDetails from './components/product-details';
import Contact from './components/contact';
import Error  from './components/404';

// import i18n (needs to be bundled ;))
import './i18n';


class Root extends Component {
    render() {
        return(
            <Suspense fallback='loading'>
                <Router basename="/">
                    <div>
                    <Switch>
                        <Route exact path="/" component={HomeV5} />
                        <Route path="/properties" component={ Shop } />
                        <Route path="/property-details/:propertyId" component={ ProdductDetails } />
                        <Route path="/contact" component={ Contact } />

                        <Route component={Error} /> {/* Ruta para capturar cualquier otra ruta */}
                    </Switch>
                    </div>
                </Router>
            </Suspense>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
