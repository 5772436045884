import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './shop-sidebar';
import Pagination from '../shared-components/Pagination';
import { useTranslation } from 'react-i18next';
import { amenitiesList } from '../../helpers/constants'

const ShopGridV1 = () => {

	const { t } = useTranslation();

	const [data, setData] = useState([]);
	const [dataCount, setDataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsForPage, setItemsForPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortingType, setSortingType] = useState('');
  const [propertiesTypes, setPropertiesTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [amenities, setAmenities] = useState(amenitiesList);



	// Crea un array filtrando data en busqueda del la propiedad pasada en typeItem y cuenta las apariciones
	const getFilterItems = (data, typeItem) => {
		// Crear un objeto para almacenar las categories únicas
		const uniqueItems = {};

		// Recorrer el array de objetos
		data.forEach((obj) => {

			const label = obj[typeItem];
			// Verificar si la category ya existe en el objeto
			if (!uniqueItems[label]) {
				// Si no existe, agregarla con count: 1 y checked: true
				uniqueItems[label] = { label: label, count: 1, checked: true };
			} else {
				// Si ya existe, incrementar el contador
				uniqueItems[label].count++;
			}
		});

		// Convertir el objeto a un array de valores
		const result = Object.values(uniqueItems);

		return result;
	}

	// Filtra las properties que incluyen en amenities todas la amenities que se le pasan en el array amenities
	const filterByAmenities = (properties, amenities) => {
		if (amenities.length === 0) {
			return [...properties];
		} else {
			return properties.filter(property => {
				return amenities.every(amenity => 
					property.amenities.some(propertyAmenity => propertyAmenity.name === amenity)
				);
			});
		}
	}

	const handleChangeItemForPage = (event) => {
		try {
			setItemsForPage(event.target.value);
		} catch (error) {
			console.error('Error en handleChangeItemForPage:', error);
		}
  };

	const handleChangeSortingType = (event) => {
		try {
			setSortingType(event.target.value);
			setCurrentPage(1);
		} catch (error) {
			console.error('Error en handleChangeSortingType:', error);
		}
  };

	const filteredResults = () => {

		let newResults = [
			...data
		]

		if (searchValue && searchValue.length > 0) {
			newResults = data.filter(property => 
				(property?.ubication && property.ubication.toLowerCase().includes(searchValue.toLowerCase())) ||
				(property?.title && property.title.toLowerCase().includes(searchValue.toLowerCase()))
			);
		}

		// Filter by Property type, default all
		const checkedPropertyTypes = propertiesTypes.filter(item => item.checked).map(item => item.label);
		const filterPropertyType = newResults.filter(item => checkedPropertyTypes.includes(item.property_type));

		// Filter by Category, default all
		const checkedCategories = categories.filter(item => item.checked).map(item => item.label);
		const filterCategories = filterPropertyType.filter(item => checkedCategories.includes(item.category));

		// Filter by neighborhoods, default all
		const checkedNeighborhoods = neighborhoods.filter(item => item.checked).map(item => item.label);
		const filterNeighborhoods = filterCategories.filter(item => checkedNeighborhoods.includes(item.neighborhood));
		
		// Filter by cities, default all
		const checkedCities = cities.filter(item => item.checked).map(item => item.label);
		const filterCities = filterNeighborhoods.filter(item => checkedCities.includes(item.city));

		// Filter by rooms, default all
		const checkedRooms = rooms.filter(item => item.checked).map(item => item.label);
		const filterRooms = filterCities.filter(item => checkedRooms.includes(item.rooms));

		// Filter by amenities, default no filter, only filter if checkedAmenities > 0
		const checkedAmenities = amenities.filter(item => item.checked).map(item => item.label);
		const filterAmenities = filterByAmenities(filterRooms, checkedAmenities);

		if (sortingType !== '') {
			switch (sortingType) {
				case 'ASC':
					filterAmenities.sort((a, b) => a.price - b.price);
					break;
				case 'DESC':
					filterAmenities.sort((a, b) => b.price - a.price);
					break;
				default:
					break;
			}
		}
		// Set dataCount for Pagination module with results of all filters
		if (dataCount !== filterAmenities.length) {
			setDataCount(filterAmenities.length);
			setCurrentPage(1);
		}
		return filterAmenities.slice((currentPage - 1) * itemsForPage, itemsForPage * currentPage);
	};
	
	const fetchData = useCallback(async () => {
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + '/properties');
			const data = await response.json();
			// console.log(data)
			setData(data.data.results);
			setPropertiesTypes(getFilterItems(data.data.results, 'property_type'))
			setCategories(getFilterItems(data.data.results, 'category'))
			setNeighborhoods(getFilterItems(data.data.results, 'neighborhood'))
			setCities(getFilterItems(data.data.results, 'city'))
			setRooms(getFilterItems(data.data.results, 'rooms'))
			setDataCount(data.data.count);
			setIsLoading(false);
		} catch (error) {
			console.error('Error al obtener datos de la API', error);
			setIsLoading(false);
		}
	},[]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


	let publicUrl = process.env.PUBLIC_URL+'/'
	let imagesUrl = process.env.REACT_APP_API_URL_IMAGES

	return (
		<div>
			<div className="ltn__product-area ltn__product-gutter">
				<div className="container">
					<div className="row">
						<div className="col-lg-8  mb-100">
							<div className="ltn__shop-options">
							<ul className="justify-content-start">
								<li>
								<div className="ltn__grid-list-tab-menu ">
									<div className="nav">
									<a className="active show" data-bs-toggle="tab" href="#liton_product_grid"><i className="fas fa-th-large" /></a>
									<a data-bs-toggle="tab" href="#liton_product_list"><i className="fas fa-list" /></a>
									</div>
								</div>
								</li>
								<li className="d-none">
									<div className="showing-product-number text-right">
										<span>Showing 1–12 of 18 results</span>
									</div> 
								</li>
								<li>
								<div className="short-by text-center">
									<select value={sortingType} onChange={handleChangeSortingType} className="nice-select">
										<option value={''}>{t('pageProperties.clasification.default')}</option>
										<option value={'ASC'}>{t('pageProperties.clasification.priceASC')}</option>
										<option value={'DESC'}>{t('pageProperties.clasification.priceDESC')}</option>
									</select>
								</div> 
								</li>
								<li>
								<div className="short-by text-center">
									<select value={itemsForPage} onChange={handleChangeItemForPage} className="nice-select">
										<option value="10">{t('pageProperties.forPage')} 10</option>
										<option value="20">{t('pageProperties.forPage')} 20</option>
										<option value="30">{t('pageProperties.forPage')} 30</option>
										<option value="50">{t('pageProperties.forPage')} 50</option>
										<option value="100">{t('pageProperties.forPage')} 100</option>
									</select>
								</div> 
								</li>
							</ul>
							</div>
							<div className="tab-content">
								<div className="tab-pane fade active show" id="liton_product_grid">
									<div className="ltn__product-tab-content-inner ltn__product-grid-view">
										<div className="row">
											<div className="col-lg-12">
												{/* Search Widget */}
												<div className="ltn__search-widget mb-30">
													<form>
														<input 
															type="text" 
															name="search"
															placeholder={t('pageProperties.searchPlaceHolder')}
															value={searchValue}
        											onChange={(e) => {setSearchValue(e.target.value); setCurrentPage(1);}}
														/>
														{searchValue.length > 0 && <button onClick={()=> {setSearchValue(''); setCurrentPage(1);}} ><i className="fa-solid fa-xmark" /></button>}
													</form>
												</div>
											</div>
											{isLoading ? (
												<p style={{paddingLeft: '20px'}}>{t('loading')}</p>
											) : (
												filteredResults().map((item, index)=>(
													<div key={`index-${index}`} className="col-xl-6 col-sm-6 col-12">
														<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
															<div className="product-img go-top">
																<Link target={'blank'} to={`property-details/${item.id}`}>
																	{item.images[0]?.image_path ? (
																			<img width={367} height={281} src={imagesUrl+item.images[0]?.image_path} alt="#" />
																		) : (
																			<img width={367} height={281} src={publicUrl+"assets/img/no-image.png"} alt="#" />
																		)
																	}
																</Link>
																{/* Circulo dentro de la imagen principal */}
																{/* <div className="real-estate-agent">
																	<div className="agent-img">
																	<Link to="/shop"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
																	</div>
																</div> */}
															</div>
															<div className="product-info">
																<div className="product-badge">
																	<ul>
																		<li className="sale-badg">{t(`elementsProperties.category.${item.category}`)}</li>
																	</ul>
																</div>
																<h2 className="product-title go-top"><Link target={'blank'} to={`property-details/${item.id}`}>{item.title}</Link></h2>
																<h6 className="product-subtitle"><Link target={'blank'} to={`property-details/${item.id}`}>{item.ubication ? item.ubication : ` `}</Link></h6>
																<div className="product-img-location go-top">
																	<ul>
																	<li>
																		<Link target={'blank'} to={`property-details/${item.id}`}><i className="flaticon-pin" />{item.city} - {item.neighborhood}</Link>
																	</li>
																	</ul>
																</div>
																<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
																	<li><span>{item.rooms} </span>
																		{t('elementsProperties.rooms')}
																	</li>
																	<li><span>{item.bathrooms} </span>
																		{t('elementsProperties.bathrooms')}
																	</li>
																	<li><span>{item.size} </span>
																		{t('elementsProperties.m2')}
																	</li>
																</ul>
																<div className="product-hover-action">
																	<ul>
																		{/* <li>
																			<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
																			<i className="flaticon-expand" />
																			</a>
																		</li>
																		<li>
																			<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
																			<i className="flaticon-heart-1" /></a>
																		</li> */}
																		<li className="go-top">
																			<Link target={'blank'}  to={`property-details/${item.id}`} title={t('showDetails')}>
																				<i className="fas fa-eye" />
																			</Link>
																		</li>
																	</ul>
																</div>
															</div>
															<div className="product-info-bottom">
																<div className="product-price">
																	<span>{Intl.NumberFormat('es-ES').format(item.price)}€<label>{item.category === 'rent' && t('elementsProperties.month')}</label></span>
																</div>
															</div>
														</div>
													</div>
												)
											))}
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="liton_product_list">
									<div className="ltn__product-tab-content-inner ltn__product-list-view">
										<div className="row">
											<div className="col-lg-12">
												{/* Search Widget */}
												<div className="ltn__search-widget mb-30">
													<form>
														<input 
															type="text" 
															name="search"
															placeholder={t('pageProperties.searchPlaceHolder')}
															value={searchValue}
															onChange={(e) => {setSearchValue(e.target.value); setCurrentPage(1);}}
														/>
														{searchValue.length > 0 && <button onClick={()=> {setSearchValue(''); setCurrentPage(1);}} ><i className="fa-solid fa-xmark" /></button>}
													</form>
												</div>
											</div>
											{/* ltn__products-items */}
											{isLoading ? (
												<p style={{paddingLeft: '20px'}}>{t('loading')}</p>
													) : (
														filteredResults().map((item, index)=>(
															/* ltn__product-item */
															<div key={`index-${index}`} className="col-lg-12">
																<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
																	<div className="product-img go-top">
																		<Link target={'blank'} to={`property-details/${item.id}`}>
																		{item.images[0]?.image_path ? (
																				<img src={imagesUrl+item.images[0]?.image_path} alt="#" />
																			) : (
																				<img width={367} height={281} src={publicUrl+"assets/img/no-image600x600.png"} alt="#" />
																			)
																		}
																	</Link>
																	</div>
																	<div className="product-info">
																		<div className="product-badge-price">
																			<div className="product-badge">
																				<ul>
																					<li className="sale-badg">{t(`elementsProperties.category.${item.category}`)}</li>
																				</ul>
																			</div>
																			<div className="product-price">
																				<span>{Intl.NumberFormat('es-ES').format(item.price)}€<label>{item.category === 'rent' && t('elementsProperties.month')}</label></span>
																			</div>
																		</div>
																		<h2 className="product-title go-top"><Link target={'blank'} to={`property-details/${item.id}`}>{item.title}</Link></h2>
																		<h6 className="product-subtitle"><Link target={'blank'} to={`property-details/${item.id}`}>{item.ubication ? item.ubication : ` `}</Link></h6>
																		<div className="product-img-location go-top">
																			<ul>
																				<li>
																					<Link target={'blank'} to={`property-details/${item.id}`}><i className="flaticon-pin" />{item.city} - {item.neighborhood}</Link>
																				</li>
																			</ul>
																		</div>
																		<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
																			<li><span>{item.rooms} </span>
																				{t('elementsProperties.rooms')}
																			</li>
																			<li><span>{item.bathrooms} </span>
																				{t('elementsProperties.bathrooms')}
																			</li>
																			<li><span>{item.size} </span>
																			{t('elementsProperties.m2')}
																			</li>
																		</ul>
																	</div>
																	<div className="product-info-bottom">
																		<div className="real-estate-agent">
																			{/* <div className="agent-img">
																				<Link to="/shop"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
																			</div> */}
																			{/* <div className="agent-brief">
																				<h6><Link to="/team-details">William Seklo</Link></h6>
																				<small>Estate Agents</small>
																			</div> */}
																		</div>
																		<div className="product-hover-action">
																			<ul>
																				{/* <li>
																					<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
																					<i className="flaticon-expand" />
																					</a>
																				</li>
																				<li>
																					<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
																					<i className="flaticon-heart-1" /></a>
																				</li> */}
																				<li className="go-top">
																					<Link target={'blank'} to={`property-details/${item.id}`} title={t('showDetails')}>
																						<i className="fas fa-eye" />
																					</Link>
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														)
											))}
											{/*  */}
										</div>
									</div>
								</div>
							</div>
							<div className="ltn__pagination-area text-center">
								<Pagination 
									onPageChange={setCurrentPage}
									totalCount={dataCount}
									currentPage={currentPage}
									pageSize={itemsForPage}
								/>
							</div>
						</div>
						<Sidebar 
							propertiesTypes={propertiesTypes}
							setPropertiesTypes={setPropertiesTypes}
							categories={categories}
							setCategories={setCategories}
							neighborhoods={neighborhoods}
							setNeighborhoods={setNeighborhoods}
							cities={cities}
							setCities={setCities}
							rooms={rooms}
							setRooms={setRooms}
							amenities={amenities}
							setAmenities={setAmenities}
						/>
					</div>
				</div>
			</div>
						
			{/* <div className="ltn__modal-area ltn__add-to-cart-modal-area">
				<div className="modal fade" id="liton_wishlist_modal" tabIndex={-1}>
					<div className="modal-dialog modal-md" role="document">
					<div className="modal-content">
						<div className="modal-header">
						<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
						</div>
						<div className="modal-body">
						<div className="ltn__quick-view-modal-inner">
							<div className="modal-product-item">
							<div className="row">
								<div className="col-12">
								<div className="modal-product-img">
									<img src={publicUrl+"assets/img/product/7.png"} alt="#" />
								</div>
								<div className="modal-product-info go-top">
									<h5><Link to="/product-details">Brake Conversion Kit</Link></h5>
									<p className="added-cart"><i className="fa fa-check-circle" />  Successfully added to your Wishlist</p>
									<div className="btn-wrapper">
									<Link to="/wishlist" className="theme-btn-1 btn btn-effect-1">View Wishlist</Link>
									</div>
								</div>
								<div className="additional-info d-none">
									<p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
									<div className="payment-method">
									<img src={publicUrl+"assets/img/icons/payment.png"} alt="#" />
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
			</div> */}

			{/* <div className="ltn__modal-area ltn__quick-view-modal-area">
				<div className="modal fade" id="quick_view_modal" tabIndex={-1}>
					<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
						<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
						</div>
						<div className="modal-body">
						<div className="ltn__quick-view-modal-inner">
							<div className="modal-product-item">
							<div className="row">
								<div className="col-lg-6 col-12">
								<div className="modal-product-img">
									<img src={publicUrl+"assets/img/product/4.png"} alt="#" />
								</div>
								</div>
								<div className="col-lg-6 col-12">
								<div className="modal-product-info">
									<div className="product-ratting">
									<ul>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
										<li><a href="#"><i className="far fa-star" /></a></li>
										<li className="review-total"> <a href="#"> ( 95 Reviews )</a></li>
									</ul>
									</div>
									<h3>Brake Conversion Kit</h3>
									<div className="product-price">
									<span>$149.00</span>
									<del>$165.00</del>
									</div>
									<div className="modal-product-meta ltn__product-details-menu-1">
									<ul>
										<li>
										<strong>Categories:</strong> 
										<span className="go-top">
											<Link to="/blog">Parts</Link>
											<Link to="/blog">Car</Link>
											<Link to="/blog">Seat</Link>
											<Link to="/blog">Cover</Link>
										</span>
										</li>
									</ul>
									</div>
									<div className="ltn__product-details-menu-2">
									<ul>
										<li>
										<div className="cart-plus-minus">
											<input type="text" defaultValue="02" name="qtybutton" className="cart-plus-minus-box" />
										</div>
										</li>
										<li>
										<a href="#" className="theme-btn-1 btn btn-effect-1" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal">
											<i className="fas fa-shopping-cart" />
											<span>ADD TO CART</span>
										</a>
										</li>
									</ul>
									</div>
									<hr />
									<div className="ltn__social-media">
									<ul>
										<li>Share:</li>
										<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
										<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
										<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
										<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
									</ul>
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
			</div> */}

			{/* <div className="ltn__modal-area ltn__add-to-cart-modal-area">
				<div className="modal fade" id="add_to_cart_modal" tabIndex={-1}>
					<div className="modal-dialog modal-md" role="document">
					<div className="modal-content">
						<div className="modal-header">
						<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">×</span>
						</button>
						</div>
						<div className="modal-body">
						<div className="ltn__quick-view-modal-inner">
							<div className="modal-product-item">
							<div className="row">
								<div className="col-12">
								<div className="modal-product-img">
									<img src={publicUrl+"assets/img/product/1.png"} alt="#" />
								</div>
								<div className="modal-product-info go-top">
									<h5 className="go-top"><Link to="/product-details">Brake Conversion Kit</Link></h5>
									<p className="added-cart"><i className="fa fa-check-circle" />Successfully added to your Cart</p>
									<div className="btn-wrapper">
									<Link to="/cart" className="theme-btn-1 btn btn-effect-1">View Cart</Link>
									<Link to="/checkout" className="theme-btn-2 btn btn-effect-2">Checkout</Link>
									</div>
								</div>
								<div className="additional-info d-none">
									<p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
									<div className="payment-method">
									<img src={publicUrl+"assets/img/icons/payment.png"} alt="#" />
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
						</div>
					</div>
					</div>
				</div>
			</div> */}
		</div>
	)
}


export default ShopGridV1