import React, { Component } from 'react';

class Social extends Component {

    render() {

    // let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__social-media">
			<ul>
				<li><a href="https://www.instagram.com/ana.hernandez.consultora/" title="Instagram"><i className="fab fa-instagram" /></a></li>
			</ul>
		</div>
        }
}

export default Social