import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error = () => {

	const { t } = useTranslation();

  let publicUrl = process.env.PUBLIC_URL+'/'

	return (
		<div className="ltn__404-area ltn__404-area-1 mb-120">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="error-404-inner text-center">
						<div className="error-img mb-30">
						<img src={publicUrl+"assets/img/others/error-1.png"} alt="#" />
						</div>
						<h1 className="error-404-title d-none">{t('page404.title')}</h1>
						<h2>{t('page404.subtitle')}</h2>
						{/* <h3>Oops! Looks like something going rong</h3> */}
						<p>{t('page404.message')}
						</p>
						<div className="btn-wrapper go-top">
						<Link to="/" className="btn btn-transparent"><i className="fas fa-long-arrow-alt-left" /> {t('page404.goback')}</Link>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
	)
}

export default Error