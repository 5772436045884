import React from 'react';
import { useTranslation } from 'react-i18next';


const Sidebar = ({
	propertiesTypes, 
	setPropertiesTypes, 
	categories, 
	setCategories,
	neighborhoods,
	setNeighborhoods,
	cities,
	setCities,
	rooms,
	setRooms,
	amenities,
	setAmenities
}) => {

	const { t } = useTranslation();

	const modifyListChecked = (data, itemSelected) => {
		const newData = data.map((item) => {
			if (item.label === itemSelected.label) {
				return {
					...item,
					checked: !itemSelected.checked
				}
			}
			return item;
		})
		return newData;
  };

	const handleSelectPorpertyType = (itemSelected) => {
		const newData = modifyListChecked(propertiesTypes, itemSelected);
		setPropertiesTypes(newData);
  };

	const handleSelectCategory = (itemSelected) => {
		const newData = modifyListChecked(categories, itemSelected);
		setCategories(newData);
  };
	
	const handleSelectCity = (itemSelected) => {
		const newData = modifyListChecked(cities, itemSelected);
		setCities(newData);
	};

	const handleSelectNeighborhood = (itemSelected) => {
		const newData = modifyListChecked(neighborhoods, itemSelected);
		setNeighborhoods(newData);
	};

	const handleSelectRooms = (itemSelected) => {
		const newData = modifyListChecked(rooms, itemSelected);
		setRooms(newData);
	};

	const handleSelectAmenities = (itemSelected) => {
		const newData = modifyListChecked(amenities, itemSelected);
		setAmenities(newData);
	};

	return (
		<div className="col-lg-4  mb-100">
			<aside className="sidebar ltn__shop-sidebar">
				{/* <h3 className="mb-10">Advance Information</h3>
				<label className="mb-30"><small>About 9,620 results (0.62 seconds) </small></label> */}
				{/* Type property Filter Widget */}
				<div className="widget ltn__menu-widget">
					<h4 className="ltn__widget-title">{t('elementsProperties.propertyType')}</h4>
					<ul>
						{propertiesTypes.map((property, index) => (
							<li key={`propertyTypes-${index}`} onChange={()=> handleSelectPorpertyType(property)}>
								<label className="checkbox-item">{t(`elementsProperties.type.${property.label}`)}
								<input type="checkbox" defaultChecked={property.checked} />
								<span className="checkmark" />
								</label>
								<span className="categorey-no">{property.count}</span>
							</li>
						))}
					</ul>
					<hr />
					{/* Amenities Filter Widget */}
					<h4 className="ltn__widget-title">{t('elementsProperties.amenitiesTitle')}</h4>
					<ul>
						{amenities.map((item, index) => (
							<li key={`categories-${index}`} onChange={()=> handleSelectAmenities(item)}>
								<label className="checkbox-item">{t(`elementsProperties.amenities.${item.label}`)}
								<input type="checkbox" defaultChecked={item.checked} />
								<span className="checkmark" />
								</label>
								{/* <span className="categorey-no">{item.count}</span> */}
							</li>
						))}
					</ul>
					<hr />
					{/* Room Filter Widget */}
					<h4 className="ltn__widget-title">{t('pageProperties.rooms')}</h4>
					<ul>
						{rooms.map((item, index) => (
							<li key={`categories-${index}`} onChange={()=> handleSelectRooms(item)}>
								<label className="checkbox-item">{item.label ? t('elementsProperties.room', {count: item.label}) : t('elementsProperties.unspecified')}
								<input type="checkbox" defaultChecked={item.checked} />
								<span className="checkmark" />
								</label>
								<span className="categorey-no">{item.count}</span>
							</li>
						))}
					</ul>
					<hr />
					{/* City Filter Widget */}
					<h4 className="ltn__widget-title">{t('pageProperties.cities')}</h4>
					<ul>
						{cities.map((item, index) => (
							<li key={`categories-${index}`} onChange={()=> handleSelectCity(item)}>
								<label className="checkbox-item">{item.label ? item.label : t('elementsProperties.f_others')}
								<input type="checkbox" defaultChecked={item.checked} />
								<span className="checkmark" />
								</label>
								<span className="categorey-no">{item.count}</span>
							</li>
						))}
					</ul>
					<hr />
					{/* Neighborhood Filter Widget */}
					<h4 className="ltn__widget-title">{t('pageProperties.neighborhoods')}</h4>
					<ul>
						{neighborhoods.map((item, index) => (
							<li key={`categories-${index}`} onChange={()=> handleSelectNeighborhood(item)}>
								<label className="checkbox-item">{item.label ? item.label : t('elementsProperties.m_others')}
								<input type="checkbox" defaultChecked={item.checked} />
								<span className="checkmark" />
								</label>
								<span className="categorey-no">{item.count}</span>
							</li>
						))}
					</ul>
					<hr />
					<h4 className="ltn__widget-title">{t('pageProperties.category')}</h4>
					<ul>
						{categories.map((item, index) => (
							<li key={`categories-${index}`} onChange={()=> handleSelectCategory(item)}>
								<label className="checkbox-item">{t(`elementsProperties.category.${item.label}`)}
								<input type="checkbox" defaultChecked={item.checked} />
								<span className="checkmark" />
								</label>
								<span className="categorey-no">{item.count}</span>
							</li>
						))}
					</ul>
				</div>
				{/* Category Widget */}
				{/* <div className="widget ltn__menu-widget ">
					<h4 className="ltn__widget-title ltn__widget-title-border">Product categories</h4>
					<ul>
						<li><a href="#">Body <span><i className="fas fa-long-arrow-alt-right" /></span></a></li>
						<li><a href="#">Interior <span><i className="fas fa-long-arrow-alt-right" /></span></a></li>
						<li><a href="#">Lights <span><i className="fas fa-long-arrow-alt-right" /></span></a></li>
						<li><a href="#">Parts <span><i className="fas fa-long-arrow-alt-right" /></span></a></li>
						<li><a href="#">Tires <span><i className="fas fa-long-arrow-alt-right" /></span></a></li>
						<li><a href="#">Uncategorized <span><i className="fas fa-long-arrow-alt-right" /></span></a></li>
						<li><a href="#">Wheel <span><i className="fas fa-long-arrow-alt-right" /></span></a></li>
					</ul>
				</div> */}
			</aside>
		</div>
	)
  
}

export default Sidebar;
