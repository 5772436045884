import React, { Component } from 'react';

class ContactInfo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
				<div className="row">
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/10.png"} alt="Icon" />
						</div>
						<h3>Dirección de correo</h3>
						<p>ana.asesorainmo@gmail.com</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/11.png"} alt="Icon" />
						</div>
						<h3>Número de teléfono</h3>
						<p>651493720</p>
					</div>
					</div>
					<div className="col-lg-4">	
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
							<div className="ltn__contact-address-icon">
							<img src={publicUrl+"assets/img/icons/12.png"} alt="Icon" />
							</div>
							<h3>Dirección oficina</h3>
							<p>Terrassa <br /></p>
						</div>
					</div>
					{/* Form Widget */}
					{/* <div className="col-lg-12">
						<div className="widget ltn__form-widget">
							<h4 className="ltn__widget-title ltn__widget-title-border-2">Drop Messege For Book</h4>
							<form action="#">
								<input type="text" name="yourname" placeholder="Your Name*" />
								<input type="text" name="youremail" placeholder="Your e-Mail*" />
								<textarea name="yourmessage" placeholder="Write Message..." defaultValue={""} />
								<button type="submit" className="btn theme-btn-1">Send Messege</button>
							</form>
						</div>
					</div> */}
				</div>
				</div>
			</div>
        }
}

export default ContactInfo