// import { directive } from '@babel/types';
import React from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

let publicUrl = process.env.PUBLIC_URL+'/'

const emptyImages = [
  {
    original: publicUrl+"assets/img/no-image.png",
    thumbnail: publicUrl+"assets/img/no-image.png",
  }
];

const Gallery = ({ images }) => {
  
  return (
    <>
      {images.length === 0 ? (
        <ImageGallery showPlayButton={false} items={emptyImages} />
      ) : (
        <ImageGallery showPlayButton={false} items={images} style={{
          objectFit: 'fill',
        }}/>
      )}
    </>
  );
}

export default Gallery
