import React, { useState, useEffect, useCallback } from 'react';
import Gallery from './gallery';

import { useTranslation } from 'react-i18next';

const ShopDetails = ({ data }) => {

	const { t } = useTranslation();

	const [imagesGallery, setImagesGallery] = useState([]);

	// const publicUrl = process.env.PUBLIC_URL+'/'
	const imagesUrl = process.env.REACT_APP_API_URL_IMAGES

	const renderColumn = (columnIndex) => {
		const columnItems = [];
		if (Array.isArray(data.amenities) && data.amenities.length > 0) {
			for (let i = columnIndex; i < data.amenities.length; i += 3) {
				columnItems.push(
					{...data.amenities[i]}
				);
			}

		}
		return columnItems;
	};

	const createDataGallery = useCallback(() => {
		if (Array.isArray(data.images) && data.images.length > 0) {
			const newData = data.images.map((item) => (
												{
													'original': imagesUrl+item.image_path,
													'thumbnail': imagesUrl+item.image_path,
												}
											));
			setImagesGallery(newData);
		}
	},[data.images, imagesUrl])

	useEffect(() => {
		if (Object.keys(data).length > 0) {
			createDataGallery();
		}
	}, [data, createDataGallery])
	



	return (
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12">
						<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
							<div className="ltn__blog-meta">
							{/* <Gallery /> */}
								<ul>
									<li className="ltn__blog-category">
										<p to="#">{t(`elementsProperties.type.${data.property_type}`)}</p>
									</li>
									<li className="ltn__blog-category">
										<p className="bg-orange" to="#">
											{t(`elementsProperties.category.${data.category}`)}
										</p>
									</li>
									{/* <li className="ltn__blog-date">
										<i className="far fa-calendar-alt" />May 19, 2021
									</li>
									<li>
										<Link to="#"><i className="far fa-comments" />35 Comments</Link>
									</li> */}
								</ul>
							</div>
							<h1>{data?.title}</h1>
							<label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span>{` ${data.ubication}, ${data.city} - ${data.neighborhood}`}</label>
							<h4 className="title-2">{t('elementsProperties.galery')}</h4>
							<div className="ltn__property-details-gallery mb-30">
								<div className="row">
									<Gallery images={imagesGallery} />
								</div>
							</div>
							<h4 className="title-2">{t('elementsProperties.description')}</h4>
							<p>{data.description}</p>
							{/* <h4 className="title-2">Property Detail</h4>  
							<div className="property-detail-info-list section-bg-1 clearfix mb-60">                          
								<ul>
									<li><label>Property ID:</label> <span>HZ29</span></li>
									<li><label>Home Area: </label> <span>120 sqft</span></li>
									<li><label>Rooms:</label> <span>7</span></li>
									<li><label>Baths:</label> <span>2</span></li>
									<li><label>Year built:</label> <span>1992</span></li>
								</ul>
								<ul>
									<li><label>Lot Area:</label> <span>HZ29 </span></li>
									<li><label>Lot dimensions:</label> <span>120 sqft</span></li>
									<li><label>Beds:</label> <span>7</span></li>
									<li><label>Price:</label> <span>2</span></li>
									<li><label>Property Status:</label> <span>For Sale</span></li>
								</ul>
							</div> */}
							<h4 className="title-2">{t('elementsProperties.features')}</h4>
							<div className="property-detail-feature-list clearfix mb-45">                            
								<ul>
									<li>
										<div className="property-detail-feature-list-item">
											<i className="flaticon-measure" />
											<div>
												<h6>{t('elementsProperties.size')}</h6>
												<small>{data.size}/{t('elementsProperties.m2')}</small>
											</div>
										</div>
									</li>
									<li>
										<div className="property-detail-feature-list-item">
											<i className="flaticon-double-bed" />
											<div>
												<h6>{t('elementsProperties.rooms')}</h6>
												<small>{data.rooms}</small>
											</div>
										</div>
									</li>
									<li>
										<div className="property-detail-feature-list-item">
											<i className="flaticon-bathtub" />
											<div>
												<h6>{t('elementsProperties.bathrooms')}</h6>
												<small>{data.bathrooms}</small>
											</div>
										</div>
									</li>
									<li>
										<div className="property-detail-feature-list-item">
											<i className="fa fa-euro" />
											{/* <i className="fa fa-money-bill-wave" /> */}
											<div>
												<h6>{t('elementsProperties.price')}</h6>
												<small>{Intl.NumberFormat('es-ES').format(data.price)} €{data.category === 'rent' && t('elementsProperties.month')}</small>
											</div>
										</div>
									</li>
									<li>
										<div className="property-detail-feature-list-item">
											<i className="flaticon-financial" />
											<div>
												<h6>{t('elementsProperties.pricem2')}</h6>
												<small>{Intl.NumberFormat('es-ES').format(Math.floor(data.price / data.size))} €/{t('elementsProperties.m2')}</small>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<h4 className="title-2 mb-10">{t('elementsProperties.amenitiesTitle')}</h4>
							<div className="property-details-amenities mb-60">
								<div className="row">
									<div className="col-lg-4 col-md-6">
										<div className="ltn__menu-widget">
											<ul style={{cursor: 'none'}}>
												{renderColumn(0).map((item, index)=>(
													<li key={`amenity-${index}`}>
														<label style={{cursor: 'auto'}} className="checkbox-item">{t(`elementsProperties.amenities.${item.name}`)}
															<input type="checkbox" defaultChecked="checked" disabled/>
															<span className="checkmark" />
														</label>
													</li>
												))}
											</ul>
										</div>
									</div>
									<div className="col-lg-4 col-md-6">
										<div className="ltn__menu-widget">
											<ul>
												{renderColumn(1).map((item, index)=>(
													<li key={`amenity-${index}`}>
														<label style={{cursor: 'auto'}} className="checkbox-item">{t(`elementsProperties.amenities.${item.name}`)}
															<input type="checkbox" defaultChecked="checked" disabled/>
															<span className="checkmark" />
														</label>
													</li>
												))}
											</ul>
										</div>
									</div>
									<div className="col-lg-4 col-md-6">
										<div className="ltn__menu-widget">
											<ul>
												{renderColumn(2).map((item, index)=>(
													<li key={`amenity-${index}`}>
														<label style={{cursor: 'auto'}} className="checkbox-item">{t(`elementsProperties.amenities.${item.name}`)}
															<input type="checkbox" defaultChecked="checked" disabled/>
															<span className="checkmark" />
														</label>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
							{/* <h4 className="title-2">{t('elementsProperties.localization')}</h4>
							<div className="property-details-google-map mb-60">
								<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11939.20237832219!2d2.0175253!3d41.5735601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a492c9a1aaf591%3A0xa3e026a31eef0c55!2sEstadio%20Ol%C3%ADmpico%20de%20Terrassa!5e0!3m2!1ses!2ses!4v1706697641755!5m2!1ses!2ses" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
							</div> */}
						</div>
					</div>
				</div>
			</div>
	)
}

export default ShopDetails