import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-right-sidebar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';



const Shop_V1 = () => {
    
    const { t } = useTranslation();

    return (
        <div>
            <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
            <PageHeader subheader={t('properties')}/>
            <ShogGrid />
            <CallToActionV1 />
            <Footer />
        </div>
    )
}

export default Shop_V1

