import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v2';
import ProductDetails from './shop-components/shop-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const Product_Details = () => {

    const { t } = useTranslation();

    const history = useHistory();
    const { propertyId } = useParams();
    const [data, setData] = useState({});
    const [images, setImages] = useState([]);
	// const [dataCount, setDataCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/properties/' + propertyId);
                const data = await response.json();
                if (data.status === false) {
                    history.push("/properties");
                }
                // console.log(data)
                setData(data.data.results);
                setImages(data.data.results.images);
                // setDataCount(data.data.count);
                setIsLoading(false);
            } catch (error) {
                console.error('Error al obtener datos de la API', error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [propertyId, history]);

    return <div>
        <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
        <PageHeader headertitle="" subheader={isLoading ? '' : data.title} secondlevel={t('properties')} secondlevelurl='/properties' customclass="mb-0" />
        <ProductSlider images={images} isLoading={isLoading}/>
        <ProductDetails data={data} isLoading={isLoading}/>
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Product_Details

