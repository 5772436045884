import React from 'react';
// import Navbar from './global-components/navbar';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
// import ContactForm from './section-components/contact-form.js';
import Footer from './global-components/footer';

const ContactV1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="" subheader="Contacto" />
        <ContactInfo />
        {/* <ContactForm /> */}
        <Footer />
    </div>
}

export default ContactV1

