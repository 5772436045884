import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const ProductSliderV1 = ({images, isLoading}) => {

	// const publicUrl = process.env.PUBLIC_URL+'/'
	const imagesUrl = process.env.REACT_APP_API_URL_IMAGES


	const buttonStyle = {
    width: "30px",
    background: 'none',
    border: '0px'
	};

	const properties = {
			prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></button>,
			nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></button>
	}

	return (
		<div className="container"></div>
	)
	// return (
	// 	<div className="ltn__shop-details-area pb-10">
	// 		<div className="container">
	// 			<div className="row">
	// 				<div className="col-lg-12 col-md-12">
	// 					{!isLoading && (
	// 						<Slide {...properties}>
	// 							{images.map((data,index)=>(
	// 								<div key={`image${index}`} className="each-slide-effect">
	// 									<div style={{ 'backgroundImage': `url(${imagesUrl+data?.image_path})` }}>
	// 										{/* <span>Slide 1</span> */}
	// 									</div>
	// 								</div>
	// 							))}
	// 					</Slide>
	// 					)}

	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>
		
		// <div className="ltn__img-slider-area mb-90">
		// 	<div className="container-fluid">
		// 	<div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
		// 			{/* {!loading ? (
		// 				<div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
		// 					{imagesData.map((data,index)=>(
		// 						<div className="col-lg-12">
		// 							<div className="ltn__img-slide-item-4">
		// 								<a href={publicUrl+"assets/img/img-slide/31.jpg"} data-rel="lightcase:myCollection">
		// 									<img src={publicUrl+"assets/img/img-slide/31.jpg"} alt="Image" />
		// 								</a>
		// 							</div>
		// 						</div>
		// 					))}
		// 				</div>
		// 			) : (
		// 				<div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
		// 					<p style={{color: 'black'}}>LOADING</p>
		// 				</div>
		// 				// <div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
		// 				// 	<p style={{color: 'black'}}>hola 1</p>
		// 				// 	<div className="col-lg-12">
		// 				// 		<div className="ltn__img-slide-item-4">
		// 				// 			<a href={publicUrl+"assets/img/no-image.png"} data-rel="lightcase:myCollection">
		// 				// 				<img src={publicUrl+"assets/img/no-image.png"} alt="Image" />
		// 				// 			</a>
		// 				// 		</div>
		// 				// 	</div>
		// 				// </div>
		// 			)} */}
		// 		{/* <div className="col-lg-12">
		// 			<div className="ltn__img-slide-item-4">
		// 				<a href={publicUrl+"assets/img/img-slide/31.jpg"} data-rel="lightcase:myCollection">
		// 				<img src={publicUrl+"assets/img/img-slide/31.jpg"} alt="Image" />
		// 				</a>
		// 			</div>
		// 		</div> */}
		// 		{/* <div className="col-lg-12">
		// 			<div className="ltn__img-slide-item-4">
		// 				<a href={publicUrl+"assets/img/img-slide/32.jpg"} data-rel="lightcase:myCollection">
		// 				<img src={publicUrl+"assets/img/img-slide/32.jpg"} alt="Image" />
		// 				</a>
		// 			</div>
		// 		</div> */}
		// 		{/* <div className="col-lg-12">
		// 			<div className="ltn__img-slide-item-4">
		// 				<a href={publicUrl+"assets/img/img-slide/33.jpg"} data-rel="lightcase:myCollection">
		// 				<img src={publicUrl+"assets/img/img-slide/33.jpg"} alt="Image" />
		// 				</a>
		// 			</div>
		// 		</div> */}
		// 		{/* <div className="col-lg-12">
		// 			<div className="ltn__img-slide-item-4">
		// 				<a href={publicUrl+"assets/img/img-slide/34.jpg"} data-rel="lightcase:myCollection">
		// 				<img src={publicUrl+"assets/img/img-slide/34.jpg"} alt="Image" />
		// 				</a>
		// 			</div>
		// 		</div> */}
		// 		<div className="col-lg-12">
		// 			<div className="ltn__img-slide-item-4">
		// 				<a href={publicUrl+"assets/img/img-slide/35.jpg"} data-rel="lightcase:myCollection">
		// 				<img src={publicUrl+"assets/img/img-slide/35.jpg"} alt="Image" />
		// 				</a>
		// 			</div>
		// 		</div>
		// 	</div>
		// 	</div>
		// </div>
	// )
        
}

export default ProductSliderV1