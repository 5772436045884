import React from 'react';
import { usePagination, DOTS } from '../../helpers/usePagination';

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className="ltn__pagination">
      <ul>
        <li className={currentPage === 1 ? 'disabled' : ''}>
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <a onClick={onPrevious}><i className="fas fa-angle-double-left" /></a>
        </li>
        {paginationRange.map((pageNumber, id) => {
          if (pageNumber === DOTS) {
            return <li className="disabled" key={`${pageNumber}_${id}`}><a>&#8230;</a></li>;
          }

          return (
            <li
              key={`${pageNumber}_${id}`}
              className={currentPage === pageNumber ? 'active' : ''}
              onClick={() => onPageChange(pageNumber)}
            >
              <a>{pageNumber}</a>
            </li>
          );
        })}
        <li className={currentPage === lastPage ? 'disabled' : ''}>
          <a onClick={onNext}><i className="fas fa-angle-double-right" /></a>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;