import React, { Component } from 'react';

class Testimonial extends Component {

    render() {

		let publicUrl = process.env.PUBLIC_URL+'/'
		// let imagealt = 'image'

    return <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70" data-bs-bg={publicUrl+"assets/img/bg/20.jpg"}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Nuestro testimonio</h6>
			          <h1 className="section-title">Feedback de los clientes</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
			      <div className="col-lg-4">
					<a href="https://g.co/kgs/bWXjSmN">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
			              Agradecer especialmente a Ana Hernández
			              por su profesionalidad, cercanía,
			              disponibilidad y buen trato. Nos ha
			              acompañado y aconsejado en todo momento en el
			              proceso de venta de nuestro piso y estamos muy agradecidos.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-name-designation">
			                <h5>Loli Fernández Acacio</h5>
			                <label>Clienta satisfecha</label>
			              </div>
			            </div>
			          </div>
			        </div>
					</a>
			      </div>
			      <div className="col-lg-4">
					<a href="https://g.co/kgs/SQQNTYE">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						La comercial que me apoyo en todo momento ha sido Ana Hernández,
						una gran profesional que me ha asesorado en todo momento. 
						Sin ninguna duda, su puntuación es un 10. Gracias Ana</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-name-designation">
			                <h5>Sandra Acevedo</h5>
			                <label>Clienta satisfecha</label>
			              </div>
			            </div>
			          </div>
			        </div>
					</a>
			      </div>
			      <div className="col-lg-4">
					<a href="https://g.co/kgs/qQ2f2Ne">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
						Tras una mala experiencia con otro asesor inmobiliario, 
						nos recomendaron contactar con Ana Hernández. 
						Llegamos preocupados porque necesitábamos vender pronto y 
						ella nos ayudó desde el primer momento a conseguir el objetivo. 
						Nos sentirnos seguros y acompañados en todo momento. 
						Ahora mismo no contaría con otra persona si tuviera que volver a vender o comprar un piso.</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-name-designation">
			                <h5>José Lomo</h5>
							<label>Cliente satisfecho</label>
			              </div>
			            </div>
			          </div>
			        </div>
					</a>
			      </div>

			      {/*  */}
			    </div>
			  </div>
			</div>

        }
}

export default Testimonial