import React, { Component } from 'react';

class CounterV1 extends Component {

    render() {

		// let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__counterup-area section-bg-1 pt-120 pb-70">
			  <div className="container">
			    <div className="row">
			      <div className="col-md-3 col-sm-6 align-self-center">
			        <div className="ltn__counterup-item text-color-white---">
			          <div className="counter-icon">
			            <i className="flaticon-book" />
			          </div>
			          <h1><span className="counter">+8</span><span className="counterUp-icon">+</span> </h1>
			          <h6>Años de experiencia en el mercado</h6>
			        </div>
			      </div>
			      <div className="col-md-3 col-sm-6 align-self-center">
			        <div className="ltn__counterup-item text-color-white---">
			          <div className="counter-icon">
			            <i className="flaticon-office" />
			          </div>
			          <h1><span className="counter">+275</span><span className="counterUp-letter"></span><span className="counterUp-icon">+</span> </h1>
			          <h6>Pisos Vendidos</h6>
			        </div>
			      </div>
				  <div className="col-md-3 col-sm-6 align-self-center">
			        <div className="ltn__counterup-item text-color-white---">
			          <div className="counter-icon">
			            <i className="flaticon-airplane" />
			          </div>
			          <h1><span className="counter">+305</span><span className="counterUp-letter"></span><span className="counterUp-icon">+</span> </h1>
			          <h6>Clientes Satisfechos</h6>
			        </div>
			      </div>
				  <div className="col-md-3 col-sm-6 align-self-center">
			        <div className="ltn__counterup-item text-color-white---">
			          <div className="counter-icon">
			            <i className="flaticon-deal" />
			          </div>
			          <h1><span className="counter">+275</span><span className="counterUp-icon">+</span> </h1>
			          <h6>Arras firmadas</h6>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default CounterV1